import { ServiceType } from '@/models/enums';

export interface StoreLinks {
  /**
   * Current App Store links, based on service type
   */
  appStoreLink: Ref<string>;
  /**
   * Current Play Store links, based on service type
   */
  playStoreLink: Ref<string>;
  /**
   * Current dynamic link, based on service type
   */
  dynamicStoreLink: Ref<string>;
  /**
   * Whether the current service type has a configured static link
   */
  hasStaticLinks: ComputedRef<boolean>;
  /**
   * Whether the current service type has a configured dynamic link
   */
  hasDynamicLink: ComputedRef<boolean>;
}

export function useAppStoreLinks(
  serviceType: MaybeRef<ServiceType | undefined>
): StoreLinks {
  const { clientState } = storeToRefs(useClientStore());

  const { isSmAndDown } = useFBreakpoints();

  const playStoreLink = ref('');
  const appStoreLink = ref('');
  const dynamicStoreLink = ref('');

  watchEffect(() => {
    appStoreLink.value =
      unref(serviceType) === ServiceType.Sharing
        ? clientState.value.sharingAppStoreLink
        : clientState.value.leasingAppStoreLink;

    playStoreLink.value =
      unref(serviceType) === ServiceType.Sharing
        ? clientState.value.sharingPlayStoreLink
        : clientState.value.leasingPlayStoreLink;

    dynamicStoreLink.value = isSmAndDown.value
      ? unref(serviceType) === ServiceType.Sharing
        ? clientState.value.sharingDynamicLink
        : clientState.value.leasingDynamicLink
      : '?app=1';
  });

  const hasStaticLinks = computed(() => {
    if (!unref(serviceType)) return false;

    switch (unref(serviceType)) {
      case ServiceType.Sharing:
        return Boolean(
          clientState.value.services.sharing &&
            (clientState.value.sharingAppStoreLink ||
              clientState.value.sharingPlayStoreLink)
        );
      case ServiceType.Leasing:
        return Boolean(
          clientState.value.services.leasing &&
            (clientState.value.leasingAppStoreLink ||
              clientState.value.leasingPlayStoreLink)
        );
      default:
        return false;
    }
  });

  const hasDynamicLink = computed(() => {
    if (!unref(serviceType)) return false;

    switch (unref(serviceType)) {
      case ServiceType.Sharing:
        return Boolean(
          clientState.value.services.sharing &&
            clientState.value.sharingDynamicLink
        );
      case ServiceType.Leasing:
        return Boolean(
          clientState.value.services.leasing &&
            clientState.value.leasingDynamicLink
        );
      default:
        return false;
    }
  });

  return {
    appStoreLink,
    playStoreLink,
    dynamicStoreLink,
    hasStaticLinks,
    hasDynamicLink,
  };
}
