<template lang="pug">
.FcwMobileAppStoreButtons
  template(v-if="mode === 'dynamic' && hasDynamicLink")
    FcwButtonLink(
      :class="buttonClass"
      :location="dynamicStoreLink"
      v-bind="dynamicStoreButtonStyle"
      static
    ) {{ dynamicStoreLinkText }}
  template(
    v-else-if="(mode === 'static' || (mode === 'dynamic' && !hasDynamicLink)) && hasStaticLinks"
  )
    .FcwMobileAppStoreButtons__static
      FcwMobileAppStoreButton(
        v-if="appStoreLink"
        :locale="locale"
        :url="appStoreLink"
        type="app-store"
        @click="emit('open-ios')"
      )
      FcwMobileAppStoreButton(
        v-if="playStoreLink"
        :locale="locale"
        :url="playStoreLink"
        type="play-store"
        @click="emit('open-android')"
      )
</template>

<style lang="stylus">
.FcwMobileAppStoreButtons__static
  display flex
  gap rem(16)
</style>

<script setup lang="ts">
import { ServiceType } from '@/models/enums';

import type { FcwButtonLinkProps } from '@/components/molecules/FcwButtonLink.vue';

export interface FcwMobileAppStoreButtonsProps {
  /**
   * Display mode of the button. Can be either:
   * - 'static': renders native app stores buttons
   * - 'dynamic': renders a FButton which uses a dynamic link, with fallback of static links if dynamic link empty
   */
  mode: 'static' | 'dynamic';
  /**
   * Service type
   */
  serviceType: ServiceType;
  /**
   * On dynamic mode, outline the button
   */
  outlined?: boolean;
  /**
   * Optional class applied to the button
   */
  buttonClass?: VueClasses;
  /**
   * Link text for dynamic button
   */
  dynamicLinkText?: string;
}

const props = withDefaults(defineProps<FcwMobileAppStoreButtonsProps>(), {
  buttonClass: '',
  dynamicLinkText: '',
});

const emit = defineEmits<{
  (name: 'open-android'): void;
  (name: 'open-ios'): void;
}>();

const { locale } = useLocale();
const { t } = useClientI18n();

const {
  appStoreLink,
  playStoreLink,
  dynamicStoreLink,
  hasStaticLinks,
  hasDynamicLink,
} = useAppStoreLinks(toRef(() => props.serviceType));

const dynamicStoreLinkText = computed(
  () =>
    props.dynamicLinkText ||
    (props.serviceType === ServiceType.Sharing
      ? t('common__sharing')
      : t('common__leasing'))
);

const dynamicStoreButtonStyle = computed<
  Pick<FcwButtonLinkProps, 'color' | 'background' | 'outlined'>
>(() =>
  props.outlined
    ? {
        color: 'neutral--light-5',
        background: 'neutral--light-5',
        outlined: true,
      }
    : { background: 'neutral--light-5' }
);
</script>
